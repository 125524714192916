:global {
  .ant-layout-header {
    height: fit-content;
    padding: 0 20px;
    background-color: #0c2a43;
  }
  .ant-layout {
    height: 100vh;
  }
  .ant-layout-sider{
    width: 70PX !important;
    min-width: 50PX !important;
    max-width: 70PX !important;
    padding: 10PX;
    background-color: #efeff3 !important;
  }
  .ant-layout-content {
    display: flex;
    overflow-y: auto;
    height: 100%;
    .ant-menu-root {
      // height: calc(100% - 60px);
      flex: 1;
      background-color: #F7F8FA !important;
    }
    .ant-menu-item-selected{
      background-color:#FDF0E8;
      color:#EC701D;
    }
    .ant-menu-submenu-selected{
      .ant-menu-submenu-title{
        background-color:#FDF0E8;
        color:#EC701D;
     
      }
 
    }
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    background-color: #efeff3;
    .ant-menu-root {
      flex: 1;
      // overflow-y: auto;
    }
  }
  .ant-menu-root {
    overflow-y: auto;
    background-color: #F7F8FA;
  }
  .ant-drawer-content-wrapper {
    height: calc(100% - 60px);
    margin-top: 60px;
  }
  
}
.header {
  width: 100%;
  height: 60px !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 50PX;
    height: 50PX;
  }
  .name {
    font-size: 14PX;
    font-weight: bold;
  }
  .separator {
    border-left: 1px solid #fff;
    width: 1px;
    height: 25px;
    margin: 0 20px;
  }
  // .avatar {
  //   margin:0 10px;
  // }
}
.collapsedBtn {
  // height:43px;
  width:40PX;
  border-radius:10PX;
  margin: 0 auto;
  margin-top: 10PX;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2PX;
  text-align: center;
  color: #0C2A43;
  :global(.anticon ){
    font-size: 20PX;
    margin-bottom: 1PX;
  }
  span{
    font-size: 12PX;
  }
 
}
.drawer{
  :global(.ant-list-item){
    &:last-child{
      border-block-end:1px solid rgba(5, 5, 5, 0.06) !important;
    }
    &:first-child{
      border-block-start:1px solid rgba(5, 5, 5, 0.06) !important;
    }
  }
}
.subMenuTitle{
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  font-weight: bold;
}
:global(.ant-divider-vertical){
  border-inline-start: 5px solid #EC701D !important;
}
// .menuContent {
//   flex: 1;
//   display: flex;
// }
.menuContiner{
  flex: 1;
  display: flex;
  overflow-y:  auto;
}
.menuItem{
  cursor: pointer;
  font-size: 12PX;
  .icon{
    height:40px;
    width:42px;
    border-radius:10px;
    background-color:#E2E3E8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  &.active{
    .icon{
      background-color:#FDF0E8;
      color:#EC701D;
    }
    div{
      color:#EC701D;
    }
  }
 
  
 

}
/* 或者只隐藏滚动条的滑块部分 */
.menuContiner::-webkit-scrollbar-thumb,.subMenu::-webkit-scrollbar-thumb {
  display: none;
}
/* 对于Webkit浏览器（如Chrome和Safari） */
.menuContiner::-webkit-scrollbar,.subMenu::-webkit-scrollbar {
  display: none; /* 隐藏整个滚动条 */
  width: 5px;
}

// /* 或者只隐藏滚动条的轨道部分 */
// ::-webkit-scrollbar-track {
//   // display: none;
// }
