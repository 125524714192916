.App {
  text-align: center;
  /* width: 100%;
  height: 100%; */
  
}
:global(.ant-notification-notice-message){
  font-size: 14px !important;
  margin-bottom: 0 !important;
  color: #f2dede !important;
  font-weight: bold !important;
}
:global(.ant-notification-notice-description){
  font-size: 12px !important;
  color: #f2dede !important;
}
:global(.ant-notification-notice){
  padding: 5px 10px !important;
  height: 60px;
  line-height: 60px;
 
}
:global(.ant-notification-notice-icon){
  top: 50%;
  transform: translatey(-50%);
}
:global(.ant-notification-notice-content){
  // margin-top: 5px;
  height: 100%;
  line-height: 30px;
}
:global(.ant-notification-notice-wrapper){
  padding: 5px 10px !important;
  width: 330px !important;
  // height:100px;
  // line-height: 60px;
 
}
:global(.ant-notification-notice-with-icon){
height: 100%;

}




